<template>
	<div>
		<SettingsBrowserPreview
			:title="pageTitle"
			:url="siteUrl"
			:description="pageDescription"
			show-description
		/>
		<ZyroFieldText
			id="page-title"
			:value="pageTitle"
			:label="$t('builder.pageSettingsModal.textFields.pageTitle.label')"
			:message="$t('builder.pageSettingsModal.textFields.pageTitle.message')"
			maxlength="60"
			@input="pageTitle = $event.target.value"
		/>
		<ZyroFieldText
			id="page-description"
			:value="pageDescription"
			input-tag="textarea"
			:label="$t('builder.pageSettingsModal.textFields.pageDescription.label')"
			maxlength="160"
			@input="pageDescription = $event.target.value"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import SettingsBrowserPreview from '@/components/site-settings/pages/general/components/SettingsBrowserPreview.vue';

export default {
	components: { SettingsBrowserPreview },
	computed: {
		...mapState([
			'customDomain',
			'domain',
		]),
		...mapState('gui', ['activeModalSettings']),
		...mapGetters('pages', ['defaultPages']),
		...mapGetters(['siteUrl']),
		editedPage() {
			return this.defaultPages[this.activeModalSettings.itemId];
		},
		pageTitle: {
			get() {
				return this.editedPage.meta?.title || '';
			},
			set(newValue) {
				this.setPageData({
					type: 'default',
					payload: {
						data: { meta: { title: newValue } },
						pageId: this.activeModalSettings.itemId,
					},
				});
			},
		},
		pageDescription: {
			get() {
				return this.editedPage.meta?.description || '';
			},
			set(newValue) {
				this.setPageData({
					type: 'default',
					payload: {
						data: { meta: { description: newValue } },
						pageId: this.activeModalSettings.itemId,
					},
				});
			},
		},
	},
	methods: mapActions('pages', ['setPageData']),
};
</script>
