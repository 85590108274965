<template>
	<ZyroModal
		:title="$t('builder.pageSettingsModal.title')"
		width="360px"
		height="auto"
		@close-modal="closeAndDiscardChanges"
	>
		<ZyroTabs
			:tabs="$options.tabs"
			:current-tab="currentTab"
			@change="currentTab = $event"
		/>
		<Component
			:is="currentTab.component"
			:is-item-hidden="isItemHidden"
			@set-is-item-hidden="isItemHidden = $event"
			@set-button-mode="error = $event"
		/>
		<template slot="footer">
			<ZyroButton @click="closeAndDiscardChanges">
				{{ $t('common.cancel') }}
			</ZyroButton>
			<ZyroButton
				theme="primary"
				:disabled="error"
				@click="save"
			>
				{{ $t('common.save') }}
			</ZyroButton>
		</template>
	</ZyroModal>
</template>

<script>
import {
	mapActions,
	mapState,
	mapGetters,
} from 'vuex';

import PageSettingsGeneral from '@/components/builder-controls/page-settings/PageSettingsGeneral.vue';
import PageSettingsSeo from '@/components/builder-controls/page-settings/PageSettingsSeo.vue';
import i18n from '@/i18n/setup';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { cloneDeep } from '@/utils/object';

const tabs = [
	{
		title: i18n.t('common.general'),
		component: 'PageSettingsGeneral',
	},
	{
		title: i18n.t('common.seo'),
		component: 'PageSettingsSeo',
	},
];

export default {
	components: {
		PageSettingsGeneral,
		PageSettingsSeo,
	},
	data() {
		return {
			currentTab: this.$options.tabs[0],
			pageBeforeEdit: null,
			pageNavigationItemBeforeEdit: null,
			error: false,
			isItemHidden: false,
		};
	},
	computed: {
		...mapState('gui', ['activeModalSettings']),
		...mapState([
			'website',
			'domain',
		]),
		...mapGetters('pages', ['defaultPages']),
		...mapGetters('navigation', ['getIsItemHidden']),
	},
	mounted() {
		const { itemId } = this.activeModalSettings;

		this.pageBeforeEdit = cloneDeep(
			this.defaultPages[itemId],
		);
		this.pageNavigationItemBeforeEdit = cloneDeep(
			this.website.navigation.items[itemId],
		);
		this.isItemHidden = this.getIsItemHidden(itemId);
	},
	methods: {
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		...mapActions('navigation', [
			'setItemData',
			'showItem',
			'hideItem',
		]),
		...mapActions('pages', ['setPageData']),
		closeAndDiscardChanges() {
			const { itemId } = this.activeModalSettings;

			this.setPageData({
				type: 'default',
				payload: {
					data: this.pageBeforeEdit,
					pageId: itemId,
				},
			});
			this.setItemData({
				data: this.pageNavigationItemBeforeEdit,
				itemId,
			});
			this.closeModal();
		},
		save() {
			const { itemId } = this.activeModalSettings;

			if (this.isItemHidden) {
				this.hideItem({ itemId });
			} else {
				this.showItem({ itemId });
			}

			this.closeModal();
		},
	},
	tabs,
};
</script>
