<template>
	<div>
		<ZyroFieldText
			id="name-in-navigation"
			:value="nameInNavigation"
			:label="$t('builder.pageSettingsModal.textFields.nameInNavigation.label')"
			:error="pageNameError ? $t('validate.emptyValue') : ''"
			@input="nameInNavigation = $event.target.value"
		/>
		<ZyroFieldText
			v-if="!isHomepage"
			id="page-url"
			:value="pageUrl"
			:label="$t('builder.pageSettingsModal.textFields.pageUrl.label')"
			:message="message"
			:error="pageUrlError ? $t('validate.pageUrlExistsInSite') : ''"
			@input="pageUrl = $event.target.value"
		/>
		<ZyroSeparator />
		<ZyroFieldToggle
			id="page-is-hidden"
			:checked="hidden"
			:label="$t('builder.pageSettingsModal.textFields.pageHide.label')"
			:message="$t('builder.pageSettingsModal.textFields.pageHide.message')"
			@change="hidden = !hidden"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapActions,
	mapGetters,
} from 'vuex';

import { getValidPagePath } from '@/utils/urlValidators';

export default {
	props: {
		isItemHidden: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			pageUrlError: false,
			pageNameError: false,
		};
	},
	computed: {
		...mapState([
			'website',
			'domain',
		]),
		...mapState('gui', ['activeModalSettings']),
		...mapGetters('pages', [
			'defaultPages',
			'isPageUrlUnique',
		]),
		...mapGetters(['siteUrl']),
		message() {
			return `${this.$t('builder.pageSettingsModal.textFields.pageUrl.message')} ${this.siteUrl}${this.pageUrl}`;
		},
		nameInNavigation: {
			get() {
				return this.defaultPages[this.activeModalSettings.itemId].name;
			},
			set(newValue) {
				// remove empty space at the start of name
				const name = newValue.replace(/^\s*/, '');
				const isPageNameValid = name.length > 0;

				this.pageNameError = !isPageNameValid;

				this.setPageData({
					type: 'default',
					payload: {
						data: { name },
						pageId: this.activeModalSettings.itemId,
					},
				});
				this.$emit('set-button-mode', this.isError);
			},
		},
		pageUrl: {
			get() {
				return this.defaultPages[this.activeModalSettings.itemId].path;
			},
			set(newValue) {
				const { url } = getValidPagePath(newValue);

				this.pageUrlError = url === this.pageUrl ? false : !this.isPageUrlUnique(url);
				this.setPageData({
					type: 'default',
					payload: {
						data: { path: url },
						pageId: this.activeModalSettings.itemId,
					},
				});
				this.$emit('set-button-mode', this.isError);
			},
		},

		hidden: {
			get() {
				return this.isItemHidden;
			},
			set(newValue) {
				this.$emit('set-is-item-hidden', newValue);
			},
		},
		isHomepage() {
			return this.website.meta.homepageId === this.activeModalSettings.itemId;
		},
		isError() {
			return [
				this.pageUrlError,
				this.pageNameError,
			].some(Boolean);
		},
	},
	methods: { ...mapActions('pages', ['setPageData']) },
};
</script>
